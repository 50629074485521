.BackgroundImageItem {
  cursor: pointer;
  &--selected &__Image {
    border: 1px solid red;
    border-radius: 5px;
  }
  &:hover .BackgroundImageItem__PreviewImage{
    visibility: visible;
    top: 0;
    left: 0px;
  }

  &__PreviewImage {
    /*CSS for enlarged image*/
    position: absolute;
    z-index: 1;
    background-color: lightyellow;
    padding: 5px;
    left: -1000px;
    border: 1px dashed gray;
    visibility: hidden;
    color: black;
    text-decoration: none;
    width: 100%;
  }

  &__Image {
    width: 100%;
  }
}
