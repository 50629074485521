#imageEditAreaContainer {
  position: absolute;
}
.gridArea {
  position: relative;
  &__backGroundCaseImage {
  }
  &__cameraArea {
    position: absolute;
    border: 1px red solid;
    background-color: white;
  }
  &__exactBoundary {
    position: absolute;
  }
  .exactBoundary {
    &__backgroundImage {
      position: absolute;
    }
  }
}
