.RawStickerItem {
  position: relative;
  &:hover .AddStickerButton {
    display: block;
  }
  & .AddStickerButton {
    position: absolute;
    display: none;
    top: 70%;
    left: 70%;
    cursor: pointer;
  }
}
