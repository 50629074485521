.canvasEditor {
  position: absolute;
  background-color: rgba($color: #000000, $alpha: 0.5);
  &__actionsButtons {
    width: 100%;
    background-color: #fff;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}
